import React from 'react';
import Helmet from 'react-helmet';

import * as GetFrontEndSettingService from '../../../service/GetFrontEndSettingService';
import * as callApiUtil from '../../../utils/callApiUtil';

class newHeader extends React.Component {
  constructor(props) {
    super(props);

    this.headerRef = React.createRef();
    this.state = {
      nonJQueryHTML: null,
      loginStatus: null,
      isLoadingLoginStatus: true,
      isLoadingMetaDataTag: true,
      isLoadHeaderDone: false,
      baseUrl: process.env.REACT_APP_HEADER_URL,
    };
  }

  async loadHeaderWebComponents() {
    try {
      const loadResource = (tag, attributes) => {
        return new Promise((resolve, reject) => {
          const element = document.createElement(tag);
          Object.keys(attributes).forEach((key) => element.setAttribute(key, attributes[key]));
          element.onload = resolve;
          element.onerror = reject;
          document.head.appendChild(element);
        });
      };
      const loadScript = (src) => loadResource('script', { src, type: 'module' });
      const loadCss = (href) => loadResource('link', { href, rel: 'stylesheet' });

      await loadScript(`${process.env.REACT_APP_HEADER_WEB_COMPONENT}/header-web-component.esm.js`);
      loadCss(`${process.env.REACT_APP_HEADER_WEB_COMPONENT}/header-web-component.css`);
    } catch (error) {
      console.error('Error Loading Header Web Component', error);
    } finally {
      this.setState({ isLoadHeaderDone: true });
    }
  }

  componentDidMount() {
    this.loadHeaderWebComponents();

    callApiUtil
      .callApi('/eservice/api/authorizationController/getLoginStatus', {})
      .then((data) => {
        if (data && data.processResult && data.processResult.status == 's') {
          this.setState({
            loginStatus: data.loginStatus,
          });
        }
      })
      .finally(() => {
        this.setState({
          isLoadingLoginStatus: false,
        });
      });

    // 後端取得各頁面的 MetaTag 並串在 Header 上
    GetFrontEndSettingService.getMetaDataTag(window.location.pathname)
      .then((data) => {
        document.querySelector('head').innerHTML += data.metaData;
      })
      .finally(() => {
        this.setState({
          isLoadingMetaDataTag: false,
        });
      });
  }

  componentDidUpdate() {
    if (this.state.isLoadHeaderDone && !this.state.isLoadingLoginStatus && !this.state.isLoadingMetaDataTag) {
      this.props.callback();
    }
  }

  render() {
    // 設定登出導頁
    // if (window['fetnet_header_setLogoutLink']) {
    //   window['fetnet_header_setLogoutLink'](process.env.REACT_APP_LOGOUT_REDIRECT_URL);
    // }

    // 設定 Header 登入選項
    if (window['fetnet_menu_showDesktop']) {
      window['fetnet_menu_showDesktop'](this.state.loginStatus, 'NA');
    }

    // 設定 Header 搜尋頁面
    if (window['fetnet_header_setSearch']) {
      window['fetnet_header_setSearch']('https://www.fetnet.net/content/cbu/tw/search.html?search_type=eservice');
    }

    return (
      <React.Fragment>
        <Helmet>
          {/* 有吃到這份 CSS 設定，暫時保留並 minify (mothra-header-footer.css) */}
          <link rel='stylesheet' href={`${process.env.PUBLIC_URL}/css/mothra-header-footer.min.css`} />

          <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/fetnet_breadcrumb.js'} defer></script>
          <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/fetnet_menu.js'} defer></script>
          <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/fetnet_webtrend_rwd.js'} defer></script>
          <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/fetnet_common.js'} defer></script>
          <script src={this.state.baseUrl + '/CommonPage/js/fetnet_ssoProcessRWD.js'} defer></script>
          <script src={this.state.baseUrl + '/chatrobot/onlinechat/atscriptes/js/bundle/postMsg.js'} defer></script>
          <script src={this.state.baseUrl + '/js/mothra.js'} defer></script>
        </Helmet>

        <header>
          <div className='fui-header is-fixed'>
            <header-component ref={this.headerRef} logout-callback-url={process.env.REACT_APP_LOGOUT_REDIRECT_URL} />
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default newHeader;
